.nav-item {
  height: 50px;
}
.nav-item .nav-table {
  width: 100%;
  height: 50px;
  display: table;
}
.nav-item .nav-item-span {
  width: 100%;
  height: 50px;
  vertical-align: middle;
  display: table-cell;
}
.nav-item .nav-item-span.selected {
  border: 1px solid #444;
  background-color: #444;
  color: white;
}
.nav-item .nav-item-span:hover {
  cursor: pointer;
}
