.full-width {
  width: 100%;
}
.testimonial-div {
  border-radius: 25px;
  border: 2px solid black;
  padding: 10px;
  margin-bottom: 50px;
  width: 60%;
}
.testimonial-div.testimonial-right {
  position: relative;
  float: right;
}
.testimonial-div .testimonial-name {
  text-align: right;
}
.testimonial-div .testimonial-name a {
  color: black;
  text-decoration: underline;
}
