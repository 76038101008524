.dating-description {
  width: 100%;
  background: cyan;
  border-radius: 5%;
  height: 100%;
  padding: 20px;
}
.dating-description .dating-info-div {
  margin-top: 20px;
}
