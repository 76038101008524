.full-width {
    width: 100%;
  }
  .story-div {
    border-radius: 25px;
    border: 2px solid black;
    padding: 10px;
    margin-bottom: 50px;
    width: 60%;
  }
  .story-div.story-right {
    position: relative;
    float: right;
  }
  .story-div .story-name {
    text-align: right;
  }
  .story-div .story-name a {
    color: black;
    text-decoration: underline;
  }
  