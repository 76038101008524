body {
  height: 100%;
  width: 100%;
}
.home-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  font-size: 20px;
}
.content-section {
  width: 80%;
  height: 100%;
  padding: 2% 7% 2% 7%;
}
.nav-section {
  width: 20%;
  height: 100%;
  text-align: center;
}
.italics {
  font-style: italic;
}
