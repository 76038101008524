.doug-header {
  position: absolute;
  right: calc(20% - 100px);
  top: 5%;
  z-index: 10;
}
.doug-header img {
  border-radius: 50%;
  width: 200px;
  border: 1px solid black;
}
