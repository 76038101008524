.dating-container {
  display: flex;
  flex-direction: row;
}
.dating-container .dating-image-container {
  width: 40%;
}
.dating-container .dating-description-container {
  width: 60%;
}
