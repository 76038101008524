.facts-section {
  margin-top: 20px;
}
.facts-section .facts-header {
  font-size: 30px;
  letter-spacing: 15px;
  margin-bottom: 10px;
}
.facts-section .facts-header .facts-header-span {
  padding: 5px 0px 5px 15px;
  border: 3px solid black;
}
.facts-section .facts-list-section .facts-list .vet-sig {
  font-size: 12px;
  font-style: italic;
  color: #444;
}
